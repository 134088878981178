import React from 'react';
import ErrorNotification from '../../ErrorNotification/ErrorNotification';
import WeekPicker from '../WeekPicker/WeekPicker';
import { WeeklyTimetableLayoutViewModel } from '../../../ViewModel/bodyViewModel/bodyViewModel.types';
import { classes } from './WeeklyTimetableLayout.st.css';
import { DataHooks } from './constants';
import WeekAvailability from '../WeekAvailability/WeekAvailability';
import { AgendaSlotsViewModel } from '../../../ViewModel/agendaSlotsViewModel/agendaSlotsViewModel';
import { Button } from 'wix-ui-tpa';
import { useCalendarActions } from '../../../Hooks/useCalendarActions';

export interface WeeklyTimetableLayoutProps {
  viewModel: WeeklyTimetableLayoutViewModel;
  errorNotificationText: string;
}

const WeeklyTimetableLayout: React.FC<WeeklyTimetableLayoutProps> = ({
  viewModel: { weekPickerViewModel, slotsPerDays, slotsStatus },
  errorNotificationText,
}) => {
  const { onSlotSelected } = useCalendarActions();
  const dailySlotsFactory = (
    date: string,
    slotsViewModel: AgendaSlotsViewModel,
  ) => {
    return (
      <>
        {slotsViewModel.map(({ id }) => (
          <Button
            key={id}
            style={{ marginBottom: '2px' }}
            onClick={() => onSlotSelected([id])}
          >
            Slot id: {id}
          </Button>
        ))}
      </>
    );
  };

  return (
    <div className={classes.root} data-hook={DataHooks.WeeklyTimetableLayout}>
      <ErrorNotification errorText={errorNotificationText} />
      <WeekPicker viewModel={weekPickerViewModel!} />
      <WeekAvailability
        slotsPerDays={slotsPerDays!}
        slotsStatus={slotsStatus}
        dailySlotsFactory={dailySlotsFactory}
      />
    </div>
  );
};

export default WeeklyTimetableLayout;
